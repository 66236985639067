<template>
  <div>
    <div style="display:flex;padding:0 0 1em">
      <Upload action="aaa" :before-upload="varifyFile" multiple accept="audio/wav">
        <Button type="primary">选择音频</Button>
      </Upload>
      <p style="flex:1;padding:0 1em" class="text-mute">批量选择本地对应等级下的文章标题命名的音频，单个音频大小不超过200M。</p>
    </div>
    <Table :columns="columns" :data="hbList">
      <template slot="check" slot-scope="{ index}">
        <Checkbox v-model="hbList[index].isSelectedForAudioxf"></Checkbox>
      </template>
      <template slot="no" slot-scope="{ index}">{{index+1}}</template>
      <template slot="contentType" slot-scope="{ row }">{{row.contentType|typePipe}}</template>
      <template
        slot="contentLevel"
        slot-scope="{ row }"
      >{{row.contentLevel||((row.minLevel||'')+' - '+(row.maxLevel||''))}}</template>

      <template slot="hbTitleSlot" slot-scope="{ row, index }">
        <div
          v-if="row.existed&&!hbList[index].isSelectedForAudioxf"
          class="text-warning"
        >本任务已在待处理任务中，为避免错误，请谨慎覆盖！</div>
        <div v-else v-for="(place,ind) in filePlaces[index]" :key="ind">
          <div>{{place.title}}</div>
          <div v-if="place.file" style="display:flex;align-items:center;">
            <div class="text-primary" style="font-size:12px">{{place.file.name}}</div>
            <Icon
              type="md-close-circle"
              style="margin-left:1em;color:#ed4014;cursor:pointer;"
              title="删除"
              @click="place.file=null"
            />
          </div>
        </div>
      </template>
    </Table>
    <div style="display:flex;padding-top:1em;">
      <Button type="primary" style="width:6em;" :disabled="!isFileEnough" @click="submitFn">提交</Button>
      <p style="flex:1;padding:0 1em" class="text-mute">提交后进入“系统匹配”环节，匹配成功则进入终审，失败则返回当前“待处理”并告知失败原因。</p>
    </div>
  </div>
</template>

<script>
const specialReg = /[<\s>\\/*?|"‘’“”':^$,\-_，…—！!。：？,.]/g;
const isNameSame = function(fnm, title) {
  console.log(fnm, title);
  let str1 = fnm
    .replace(specialReg, "")
    .trim()
    .toLowerCase()
    .replace(/（(\d+)）$/, `($1)`);
  let str2 = title
    .replace(specialReg, "")
    .trim()
    .toLowerCase()
    .replace(/（(\d+)）$/, `($1)`);
  console.log(str1, str2, str1 === str2);
  return str1 === str2;
};
const maxFilesize = 200 << 20; // 200M
import {
  addUploadTasks,
  getIdsToHandleInCache
} from "./discontinuedUploadService";
export default {
  props: {
    hbList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          title: "选择",
          slot: "check",
          width: 65
        },
        {
          title: "序号",
          slot: "no",
          width: 65
        },
        {
          title: "等级",
          slot: "contentLevel",
          width: 95
        },
        {
          title: "类别",
          slot: "contentType",
          width: 98
        },
        {
          title: "文章标题",
          slot: "hbTitleSlot"
        }
      ],
      filePlaces: []
    };
  },
  computed: {
    /**
     * 返回0，没有；返回1，有但是不够；返回2，够了。
     */
    isFileEnough() {
      let hasFile = false;
      for (let i = 0; i < this.hbList.length; i++) {
        if (!this.hbList[i].isSelectedForAudioxf) {
          continue;
        }
        for (let j = 0; j < this.filePlaces[i].length; j++) {
          if (!this.filePlaces[i][j].file) {
            if (!this.hbList[i].clip) {
              return false;
            }
          } else {
            hasFile = true;
          }
        }
      }
      return hasFile;
    }
  },
  methods: {
    varifyFile(file) {
      if (file.size > maxFilesize) {
        this.$Message.warning("音频" + file.name + "超过200M");
        return false;
      }
      for (let places of this.filePlaces.filter(
        (ele, i) => this.hbList[i].isSelectedForAudioxf
      )) {
        for (let place of places) {
          if (
            isNameSame(
              place.title,
              file.name.substring(0, file.name.lastIndexOf("."))
            )
          ) {
            place.file = file;
            return false;
          }
        }
      }
      return false;
    },
    submitFn() {
      let hasErrorFile = false;
      let submitTasks = this.filePlaces.map((places, i) => {
        if (!this.hbList[i].isSelectedForAudioxf) {
          return null;
        }
        let articleInfos = [];
        places.forEach((ele, articleInd) => {
          if (!ele.file) {
            articleInfos.push(null);
            return;
          }
          if (!ele.file.size) {
            hasErrorFile = true;
            articleInfos.push(null);
            return;
          }

          let typef = ele.file.name.substring(
            1 + ele.file.name.lastIndexOf(".")
          );
          let nf = new File(
            [ele.file],
            ele.processId + `(${articleInd}).${typef}`,
            { type: ele.file.type }
          );
          articleInfos.push({
            fileName: ele.file.name,
            file: nf,
            title: ele.title
          });
        });
        return {
          articleInfos: articleInfos.filter(ele => ele && ele.file),
          taskId: places[0].taskId,
          processId: places[0].processId,
          contentLevel: this.hbList[i].contentLevel,
          contentType: this.hbList[i].contentType
        };
      });
      if (hasErrorFile) {
        this.$Message.warning(
          "存在失效的文件，请勿在提交前修改已经关联好的文件！"
        );
        return;
      }
      submitTasks = submitTasks.filter(ele => ele && ele.articleInfos.length);
      addUploadTasks(submitTasks);
      this.$emit("audioUploadFinished");
    }
  },
  beforeMount() {
    let fpsArr = [];
    this.hbList.forEach((hb, ind) => {
      fpsArr[ind] = [];
      if (
        /Verse/.test(hb.contentType) ||
        "cnIdiomComb" === hb.contentType ||
        hb.contentType === "cnFlying"
      ) {
        fpsArr[ind][0] = {
          title: hb.title,
          file: null,
          processId: hb.processInstanceId,
          taskId: hb.taskId
        };
      } else {
        hb.contentTitle.forEach((ele, i) => {
          fpsArr[ind][i] = {
            title: (ele || hb.contentTitle[0]) + `(${i + 1})`, // ele ||
            file: null,
            processId: hb.processInstanceId,
            taskId: hb.taskId
          };
        });
      }
    });
    this.filePlaces = fpsArr;
    getIdsToHandleInCache().then(ids => {
      this.hbList.forEach(hb => {
        let existed = ids.includes(hb.processInstanceId);
        this.$set(hb, "isSelectedForAudioxf", !existed);
        this.$set(hb, "existed", existed);
      });
    });
  }
};
</script>

<style scoped>
</style>